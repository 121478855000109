.likesModalContainer {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    z-index: 999999; 
}

.likesModalMain {
    width: 500px;
    padding: 30px 20px;
    background: #09172E;
    border: 1px solid #2B315C;
    border-radius: 30px;
    position: absolute;
    transform: translate(-50%, 0%);
    left: 50%;
    top: 10%;
}

@media screen and (max-width:998px) {
    .likesModalMain {
        width: 90%;
    }
}

.likesModalMain i {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
}

.avatarSectionBlk img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.likesModalMain h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 400;
    color:#fff;
    font-size: 20px;
    border-bottom: 1px solid #2B315C;
    padding-bottom: 15px;
}

.flexBoxBlk {
    display:flex;
    gap: 10px;
}

.flexBoxBlk>div:first-child {
    background:rgb(22 45 83);
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

.flexBoxBlk  {
   border-bottom: 1px solid #2B315C;
   padding-bottom: 15px;
   padding-top: 15px;
}

.flexBoxBlk:first-child {
    padding-top: 0px;
}

.flexBoxBlk:last-child {
   border:none;
   padding-bottom: 15px;
}

.flexBoxBlk div h3 {
   font-family: 'Inter', Arial, Helvetica, sans-serif;
   font-weight: 500;
   font-size: 14px;
   color: #fff;
}

.flexBoxBlk span {
    margin-left: 7px;
    color: #426093;
    font-size: 9px;
}

.flexBoxBlk p {
    color: #426093;
    font-size: 11px;
    display:block;
    margin-top:3px;
}

.container {
    margin-top: 20px;
    overflow-y: scroll;
    height: 260px;
}


.container::-webkit-scrollbar {
    width: 10px;
}

.container::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
    background: rgb(22, 61, 125);
    border-radius: 10px;
    cursor: pointer;
}

.flexBoxBlkInside {
    display:flex;
    align-items: center;
}