.postCardContainer {
    background: #09172E;
    margin-bottom: 15px;
    border: 1px solid #2B315C;
    border-radius: 15px;
    padding: 0px 25px;
}

.postCardContainer p {
    font-weight: 200;
}

.postCardContainer:last-child {
    margin-bottom: 0px;
}

p {
    color: #fff;
    font-weight: 200;
    font-size: 14px;
    line-height: 1.5;
}

.beTheFirstSectionBlk {
    font-size: 12px;
    color: #01C2F3;
}

.headerSectionStyles {
    display: flex;
    align-items: center;
    padding: 25px 0px 10px 0px;
}

.avatarSection {
    margin-right: 7px;
}

.avatarSection img {
    width: 23px;
    height: 23px;
    object-fit: cover;
    border-radius: 50%;
}

.headerSectionStyles span,
i {
    color: #fff;
    display: block;
}

.pronounSection span {
    margin-left: 7px;
    color: #426093;
    font-size: 9px;
    align-self: center;
}

.dateSection span {
    color: #586164;
    font-size: 11px;
}

.pointsGivenPill {
    margin-right: 7px;
    background: #01C2F3;
    padding: 5px 15px;
    font-weight: 700;
    font-size: 12px;
    border-radius: 60px;
}


.headerSectionStyles h3 {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
}


.preferredNameSection {
    text-transform: capitalize;
}

.preferredNameSectionFlexBox {
    display: flex;
    align-items: center;
}

.dropDownContainer {
    position: relative;
    margin-left: 10px;
    z-index: 3;
}

.dropDownBlk {
    position: absolute;
    top: 5px;
    right: 0px
}

.dropDownBlk p {
    font-size: 10px;
    text-align: center;
}

.editButton,
.ellipsisBlk,
.deleteButton {
    cursor: pointer;
}

.editButton,
.deleteButton {
    background: #0D1F3E;
    transition: background-color 0.2s ease-in-out;
    padding: 8px 20px;
}

.deleteButton:hover,
.editButton:hover {
    background: #051021;
}

.avatar {
    width: 23px;
    height: 23px;
    display: block;
    background: red;
    border-radius: 50%;
}

.recipientListBlk {
    color: #FECF0C;
    margin-left: 3px;
    display: inline-block;
    text-transform: capitalize;
}

.uploadedImage {
    width: 100%;
    margin-top: 15px;
    border-radius: 7px;
}

.footerSectionStyles {
    display: flex;
    padding: 15px 0px 0px 0px;
}

.footerSectionStyles>div:last-child {
    margin-left: auto;
}

.likeContainer {
    color: #fff;
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 20px;
    cursor: pointer;
    margin-right: auto;
    width: 155px;
}

.likeContainer p {
    font-size: 12px;
    color: #01C2F3;
}

.likeContainer i {
    font-size: 12px;
    color: #01C2F3;
}

.showAllCommentsSection {
    display: flex;
    justify-content: flex-end;
}

.showAllCommentsSection button {
    color: #01C2F3;
    font-size: 12px;
    background: none;
    border: none;
}

.hrStyles {
    width: 100%;
    height: 0.5px;
    border: 0px;
    display: block;
    margin: 15px 0px;
    background: #2B315C;
}

.spanHashTagSection {
    margin-left: 5px;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
    color: #fff;
}

.levelSectionCard p {
    font-weight: 400;
    color: #ffd52d;
    font-size: 9px;
}


.badgeSection {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 10px 0px;
}

.badgeSection div:last-child {
    margin-left: auto;
}

.badgeSection h1 {
    color: #fff;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 30px;
}

.badgeSection img {
    width: 65px;
}

.multipleImageSelection {
    display: flex;
}

.multipleImageSelection img {
    width: 35px;
    height: 35px;
    display: inline-block;
    border: 2px solid #fff;
    border-radius: 50%;
    object-fit: cover;
    background: #09172E;
}

.multipleImageSelection img:first-child {
    margin-right: -15px;
    z-index: 2;
}

.multipleImageSelection img:nth-child(2) {
    margin-right: -15px;
    z-index: 1;
}

.messageBlockSection {
    white-space: normal;
    word-wrap: break-word;
    word-break: break-word;
}

.preferredNameLink {
   text-decoration: none;
}

.preferredNameLink h3 {
    transition: color 0.2s ease-in-out;
}

.preferredNameLink:hover h3{
    color: #01C2F3;
}

.linkTaggedUsers span {
    transition: color 0.3s ease-in-out;
}

.linkTaggedUsers:hover span {
    color: #ffe375
}