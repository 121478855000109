.error {
    border-radius: 7px;
    background: #cb0000;
    color: #fff;
    position:relative;
}

.success {
    border-radius: 7px;
    background:green;
}

.error i  {
    cursor: pointer;
    position: absolute !important;
    right: 12px;
    color: #e3e3e3;
    top: 7px;
    font-size: 10px;
}