/* iCXeed Fonts */
@font-face {
  font-family: Furious-Styles;
  src: url("../../client/src/fonts/furious-styles.woff2");
}

@font-face {
  font-family: JosefinSans-Light;
  src: url("../../client/src/fonts/JosefinSans-Light.ttf");
}

@font-face {
  font-family: JosefinSans-Regular;
  src: url("../../client/src/fonts/JosefinSans-Regular.ttf");
}

@font-face {
  font-family: MangoGrotesque-SemiBold;
  src: url("../../client/src/fonts/MangoGrotesque-SemiBold.ttf");
}

:root {
  --yellow: #FECF0C;
  --blue: #01C2F3;
  --darkerBlue : #0D1F3E;
  --darkestBlue : #040c21;
}

* {
  padding: 0;
  margin: 0;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

button { 
  cursor: pointer;
}


/* Template Styles */
.main-container-blk {
  width: 1200px;
  display: block;
  margin: 0 auto;
}

.flex-box-blk {
  display: flex;
}

.inside-page-container-blk {
  background: url('./assets/img/dashboard/dashboard-bg.png') #050e25 bottom no-repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  min-height:100vh;
}

.not-available-blk {
  color: #FECF0C;
}

.all-posts-label-blk {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.all-posts-label-blk p {
  color: #394070;
  font-size: 12px;
  font-weight: 400;
}

.all-posts-label-blk>div:first-child,
.all-posts-label-blk>div:last-child {
  flex: 1;
  background:#313865;
  height:1px;
}

.post-icon-blk {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #2B315C;
  padding: 5px 20px;
  border-radius: 5px;
}

.post-icon-blk>i {
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  transition: color 0.2s ease-in-out;

}

.post-icon-blk>span{
  font-size: 10px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  transition: color 0.3s ease-in-out;
}

.post-icon-blk>i:hover,
.post-icon-blk>span:hover{
  color:#01C2F3;
}

.message-prompt-blk {
  font-size: 12px;
  display: block;
  text-align: center;
  padding: 10px;
}
.page-title-blk {
  display: flex;
  align-items: center;
  gap: 15px;
}

.page-title-blk h1 {
  font-size: 25px;
  color: #fff;
  font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
  line-height: 1.5;
}

.page-title-blk i {
  font-size: 20px;
  display: block;
  margin-top:-5px;
}

/* User Dashboard Styles */
.user-dashboard-main-section {
  display: flex;
  padding: 80px 0px;
  gap: 15px;
  min-height: 100vh;
  align-items: flex-start;
}


.left-section-dashboard, .right-section-dashboard  {
  position: sticky;
  top: 0px;
  flex: 1;
  z-index: 3;
}

.left-section-dashboard { top: -135px;}

.center-section-dashboard {
  flex: 2;
  padding-bottom: 30px;
  z-index: 2;
}

.center-section-dashboard hr {
 width: 100%;
 height: 0.5px;
 border: 0px;
 display:block;
 margin: 15px 0px;
 background:#2B315C;
}

.arrow-back-page{
  cursor: pointer;
}

/* Login Page */

.promot-message-login-page-error {
  background:rgba(0, 0, 0, 0.8);
  position: relative;
  width: 100%;
  height: 100vh;
  z-index: 999999;
}

.promot-message-login-page-error img {
    width: 40px;
    height: 40px;
    display:block;
    margin: 0 auto 10px auto;
}

.prompt-close-icon {
  cursor:pointer;
  position: absolute;
  right: 20px;
  top: 15px;
}

.promot-message-login-page-error>div:first-child{
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  padding: 30px 20px;
  background: #09172E;
  border: 1px solid #2B315C;
  border-radius: 10px;
}

.promot-message-login-page-error>div:first-child h1 {
  font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  line-height: 1.4;
  display: block;
}

#login-page-container {
  background:url('./assets/img/login/stardust-bg.png') center center #040c21;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

#login-page-container button {
  background:#FECF0C;
  border: none;
  padding: 10px;
  width: 180px;
  border-radius: 20px;
  font-weight: 600;
  transition: background-color 0.2s ease-in-out;
  position:absolute;
  bottom: 8%;
  left: 50%;
  right: 50%;
  transform: translate(-50%);
  font-size: 14px;
}

#login-page-container button:hover {
  background: #ffda46;
}

#logo-section-blk {
  position:absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 10%; 
  width:170px;
}

#logo-section-blk img {
  width: 100%;
}


#quote-section-blk {
  position:absolute;
  transform: translate(-50%,-50%);
  top: 50%;
  left: 50%;
  right: 50%;
  width: 350px;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  z-index: 99;
}

#quote-section-blk p:first-child {
  font-style: italic;
}

#quote-section-blk p:last-child {
  display:block;
  margin-top:20px;
}

.planet-header-one-image {
  position: absolute;
  left: 0%;
  width: 110px;
  transition: all 0.3s ease-in-out;
}

.planet-header-two-image {
  position: absolute;
  left: 20%;
  width: 130px;
  transition: all 0.3s ease-in-out;
}

.planet-header-three-image {
  position: absolute;
  right: 0%;
  width: 110px;
  transition: all 0.3s ease-in-out;
}

.planet-footer-one-image {
  position: absolute;
  bottom: 0;
  width:216px;
  left: 1%;
  transition: all 0.3s ease-in-out;
}


.planet-footer-two-image {
  position: absolute;
  bottom:0;
  width: 820px;
  left:50%;
  right: 50%;
  transform:translate(-50%);
  transition: all 0.3s ease-in-out;
}


.planet-footer-three-image {
  position: absolute;
  bottom: 0;
  width: 50px;
  bottom: 5%;
  right: 3%;
  transition: all 0.3s ease-in-out;
}

.planet-mid-image {
  position: absolute;
  width: 100px;
  top: 40%;
  left: 15%;
  transition: all 0.3s ease-in-out;
}

.astronaut-image {
  position: absolute;
  bottom: 0;
  width: 300px;
  bottom: 20%;
  right: 3%;
  transition: all 0.3s ease-in-out;
}

/* Leaderboard */

.leaderboard-main-section {
  padding: 80px 20px;
}

.leader-board-list-header {
  background:#0D1F3E;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
}

.leader-board-list-header div {
  padding: 15px;
}

.leader-board-list-header div h2 {
  font-size: 12px;
  color: #fff;
  font-weight: 600;
}

.leader-board-list-header div:nth-child(2) {
  flex: 1;
}

.leader-board-list-header div:first-child {
  width: 150px;
}
.leader-board-list-header div:last-child {
  width:  200px;
}

.leader-board-list-page-container {
  border:1px solid #2B315C;
  margin-top: 20px;
  border-radius: 10px;
}

.leader-board-list-page-container .leader-board-list-blk-section p {
  color:#fff;
}

.leader-board-list-blk-section {
  display:flex;
  align-items: center;
  padding: 15px;
  border-top: 1px solid #2B315C;
}

.leader-board-list-blk-section div:nth-child(2) {
  flex: 1;
}

.leader-board-list-blk-section>div:first-child {
  width: 150px;
}

.leader-board-list-blk-section div:last-child {
  width: 170px;
}


.leader-board-list-page-container .leader-board-list-blk-section h2 {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.leader-board-list-blk-section p {
  color:#475467;
  font-weight: 400;
  font-size: 11px;
}

.leader-board-list-blk-section span {
  padding: 10px;
}

.leader-board-list-blk-section-rank-bubble-one {
  background:#FECF0C;
  display:block;
  width: 30px;
  height:30px;
  border-radius: 50%;
  color:#000;
  position: relative;
}

.leader-board-list-blk-section-rank-bubble-one span {
  position: absolute;
  color:#000;
  font-weight: 800;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.leader-board-list-blk-section-rank-bubble-two {
  background: #01C2F3;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #000;
  position: relative;
}

.leader-board-list-blk-section-rank-bubble-two span {
  position: absolute;
  color: #000;
  font-weight: 800;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.leader-board-list-blk-section-rank-bubble-three {
  background: #1D458C;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #000;
  position: relative;
}

.leader-board-list-blk-section-rank-bubble-three span {
  position: absolute;
  color: #fff;
  font-weight: 800;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.leader-board-list-blk-section-email-section {
  color: #475467;
  font-size: 10px;
  font-weight: 400;
}

.leader-board-list-blk-section .flex-box-blk img{
  width: 30px;
  height: 30px;
  display:block;
  object-fit: cover;
  border-radius: 50%;
  display:block;
}

.leader-board-list-blk-section .flex-box-blk {
  gap: 10px;
}



/* Admin Create User Page */
.admin-create-user-page-container {
  background: url('./assets/img/dashboard/dashboard-bg.png') bottom no-repeat #040a1b;
  padding-top: 80px;
  padding-bottom: 80px;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.admin-create-user-page-container h1 {
  margin-bottom: 17px;
}

.admin-create-user-page-container input[type="text"], .admin-create-user-page-container textarea {
  margin-bottom: 15px;
}


.admin-create-user-page-container .react-datepicker-wrapper {
  width: 100%;
}


.edit-profile-main-container .react-datepicker-wrapper{
  width: 100%;
}

.admin-create-user-page-container .react-datepicker__input-container {
  width: 100%;
}

.admin-create-user-page-container .react-datepicker__input-container input {
  width: 100%;
  background: #0D1F3E;
  width: 100%;
  display: block;
  padding: 10px;
  border: 1px solid #2B315C;
  border-radius: 7px;
  color: #fff;
}

.create-user-button{
  background: #FECF0C;
  border-radius: 30px;
  padding: 10px 20px;
  font-weight: 600;
  color: #000;
  border: 0px;
  display:block;
  margin-left: auto;
}

.admin-create-user-page-container .flex-box-blk {
  gap: 20px;
}

.form-container-main {
  display: flex;
  gap: 15px;
}

.form-container-main>div:first-child {
  flex: 1;
  position:relative;
}

.form-container-main>div:first-child div {
  border: 1px dashed #318ed5;
  cursor:pointer;
  border-radius: 7px;
}

.form-container-main>div:last-child {
  flex:3;
}

.form-container-main>div:last-child .flex-box-blk div {
  flex: 1;
}

.close-image-preview-icon-blk-create-user {
  background: #0d1f3e !important;
  position: absolute;
  height: 25px;
  border-radius: 50% !important;
  width: 25px;
  right: 10px;
  top: 10px;
}

.close-image-preview-icon-blk-create-user i {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.select-photo-paragraph {
  text-align: center;
  padding: 10px;
}

.create-user-image-preview-blk {
  width: 100%;
  display: block;
  border-radius: 10px;
}



/* Leaderboard */

.leaderboard-card-blk>div div{
 margin-top:0px;
}

.leaderboard-card-blk>div div div{
  width: 175px;
}


/* Spinner */

.spinner-blk {
  border-top: 3px solid #01c2f3;
  border-radius: 50%;
  animation: spinAnimation 1s linear infinite;
}

.large-spinner {
  width: 100px;
  height: 100px;
  border: 15px solid #14284c;
  border-top: 15px solid #01c2f3;
}

.medium-spinner {
  width: 50px;
  height: 50px;
  border: 8px solid #14284c;
  border-top: 8px solid #01c2f3;
}

.small-spinner {
  width: 15px;
  height: 15px;
  border: 2px solid #14284c;
  border-top: 2px solid #01c2f3;
}

.extra-small-spinner {
  width: 10px;
  height: 10px;
  border: 2px solid #14284c;
  border-top: 2px solid #01c2f3;
}

.center-spinner {
  margin: 0px auto;
  display: block;
}

.left-spinner {
  margin-right: auto;
  display: block;
}

.right-spinner {
  margin-left: auto;
  display: block;
}

.page-not-found-main-container {
  width: 300px;
  position:absolute;
  top: 50%;
  left:50%;
  right: 50%;
  transform:translate(-50%,-50%);
}

.page-under-construction-main-container {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.page-not-found-main-container h1 {
  font-size: 150px;
  color:#FECF0C;
  text-align: center;
  font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.page-under-construction-main-container h1 {
  font-size: 60px;
  color: #FECF0C;
  text-align: center;
  font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
}

.page-not-found-main-container h2 {
  font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
  display:block;
  margin-bottom:10px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.page-under-construction-main-container h2 {
  font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  color: #fff;
  text-align: center;
}

.page-not-found-main-container p {
  font-size: 12px;
  font-weight: 400;
  display:block;
  color: #fff;
  text-align: center;
}

.page-under-construction-main-container p {
  font-size: 12px;
  font-weight: 400;
  display: block;
  color: #fff;
  text-align: center;
}

.page-not-found-main-container a {
  background:#FECF0C;
  display:block;
  text-align: center;
  width: 150px;
  margin:0 auto;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
  color: #000;
  margin-top: 15px;
}

.page-under-construction-main-container a {
  background: #FECF0C;
  display: block;
  text-align: center;
  width: 150px;
  margin: 0 auto;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 12px;
  text-decoration: none;
  font-weight: 600;
  color: #000;
  margin-top: 15px;
}

.ufo-image {
  position:absolute;
  top: 28%;
  right: 20%;
  transform:translate(-50%,-50%);
  width: 220px;
}

.planet-image {
  position:absolute;
  bottom:0;
  left: 20%;
  width: 170px;
}

.mid-ruby-stone-image {
  position:absolute;
  bottom:30px;
  right: 25%;
  width: 40px;
}

.largest-ruby-stone-image {
  position: absolute;
  left: 30%;
  width: 40px;
  top: 30px;
}

.smallest-ruby-stone-image {
  position: absolute;
  width: 25px;
  left: 15%;
  bottom:40%;
}

.largest-saturn-image {
  position: absolute;
  left: 5%;
  top: 10%;
  width: 180px;
}

.smallest-saturn-image {
  position: absolute;
  right: 0%;
  top: 10%;
  width: 70px;
}

.largest-planet-image {
  width: 100px;
  position:absolute;
  right:0;
  bottom: 10%;
}

.class-label-blk {
  font-family: 'JosefinSans-Regular';
  font-size: 12px;
  color: #fff;
  display: block;
  margin-bottom: 5px;
}

.edit-main-container {
  padding-top: 80px;
  padding-bottom: 20px;
}

.profile-main-container{
  padding-top: 80px;
  padding-bottom: 80px;
}

/* Create Badge Page */
.create-badge-main-container {
  display:flex;
  width: 100%;
  height: 100vh;
}

.create-badge-main-container>div:first-child {
  background: #0D1F3E;
  padding: 10px 20px;
}

.create-badge-main-container>div:last-child {
  width: 100%;
  padding: 20px;
}

.create-badge-link a {
  margin-bottom: 5px;
  padding: 8px;
  display:block;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.active-nav-admin {
    border-radius: 5px;
background: #09172E;
}

.create-badge-link .flex-box-blk {
  gap: 7px;
}

.create-badge-link .flex-box-blk i{
  font-size: 8px;
  display: block;
  align-self: center;
  margin-left: auto;
}

.create-badge-link .flex-box-blk p {
  font-weight: 400;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  text-decoration: none;
  color: #fff;
  font-size: 12px;
}

.create-badge-link img  {
  width: 15px;
  height: 15px;
  display:block;
}

.create-badge-link a:hover {
  background: #09172E;
}

.add-margin-logo {
  margin-top: 10px;
  margin-bottom: 20px;
}

.delete-modal-blk {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999999;
}

.delete-modal-blk .delete-modal-main-blk {
  width: 340px;
  padding: 30px 20px;
  background: #09172E;
  border: 1px solid #2B315C;
  border-radius: 30px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}


.delete-modal-blk .delete-modal-main-blk h1 {
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 10px;
    line-height: 1.4;
    display: block;
}

.delete-modal-blk .delete-icon-modal-hashtaglist {
  width: 40px;
  margin: 0 auto 10px auto;
  display: block;
} 

.delete-modal-main-blk .fa-solid{
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  cursor: pointer;
  color:#fff; 
}

.delete-modal-main-blk button{
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-weight: 700;
    background: #FECF0C;
    border-radius: 20px;
    border: none;
    width: 100%;
    padding: 10px;
}

.leaderboard-name-blk-link {
  text-decoration: none;
  display: inline-block;
}

.leaderboard-name-blk-link h2 {
  transition: color 0.3s ease-in-out;
}

.leaderboard-name-blk-link:hover h2 {
  color: #01C2F3;
}

.ixieChatbot-container {
  position: fixed;
  z-index: 99999;
  bottom:5%;
  right:1%;
}


.ixieChatbot-container iframe{
  width: 400px;
  height: 300px;
  border: none;
  border-radius: 10px;
  border: 1px solid #0b2f3d;
}

.ixieChatbot-container>div:first-child {
  position: absolute;
  top: -13px;
  right: 10px;
  background: #193669;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor:pointer;
}

.ixieChatbot-container>div:first-child .fa-message {
  font-size: 10px;
}

.ixieChatbot-container>div:first-child i {
  position: absolute;
  transform: translate(-50%,-50%);
  left: 50%;
  top: 50%;
}

/* Animation Keyframes */

@keyframes spinAnimation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* Media Queries */

/* Login */
@media screen and (max-width:1300px) {
  .main-container-blk {
    width: 100%;
    padding: 0px 20px;
  }
  .left-section-dashboard {
    margin-top: 80px;
  }
  .right-section-dashboard {
      margin-top: 80px;
  }
  .center-section-dashboard {
    margin-top: 80px;
  }
}

@media screen and (max-width:920px){
  .planet-header-one-image {
      width: 80px;
  }
  .planet-header-two-image {
      left: 20%;
      top:-30px;
      width: 90px;
  }
  .planet-header-three-image {
    width: 80px;
  }
  .planet-footer-one-image {
      width: 130px;
    }
  .planet-footer-two-image {
    width: 500px;
  }
  .planet-footer-three-image {
      width: 40px;
  }
  .astronaut-image {
    width: 230px;
    bottom: 30%;
  }
  .planet-mid-image {
      width: 70px;
      left: 2%;
  }

  .user-dashboard-main-section {
    display:flex;
    flex-direction: column;
  }

  .user-dashboard-main-section .left-section-dashboard {
    padding-top: 70px;
    width: 100%;
    position: relative;
    top: 0;
    margin-top:0px ;
    order: 1;
  }

  .center-section-dashboard{
    margin-top: 0px;
    width:100%;
    order:3;
  }

  .user-dashboard-main-section .right-section-dashboard{
      margin-top:0px;
      width: 100%;
      order:2;
      position: relative;
  }

  .create-badge-main-container {
    display: block;
    height: auto;
    background: #09172e;
  }

}


@media screen and (max-width:900px) {

  .ufo-image {
      right: 100px;
      width: 130px;
    }
}


@media screen and (max-width:700px) {
  .ufo-image {
    right: 0px;
    top:25%;
  }
  .smallest-saturn-image {
    top: 0px;
    width: 60px;
  }
  .planet-image {
    left:10px;
    width: 130px;
  }
  .largest-saturn-image {
    width: 130px;
    left:10px;
  }
  .largest-planet-image {
    width: 80px;
  }
}