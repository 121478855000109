.likeButton {
    background:#09172E;
    color:#fff;
    padding: 7px 15px;
    border: 1px solid #2B315C;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap:7px;
}

.isLiked {
    border: 1px solid #01C2F3;
    color: #01C2F3;
}


.likeButton i {
    font-size: 15px;
}

.isLiked i {
    color: #01C2F3;
}


.likeButton span {
    font-size: 12px;
}