.teamBlockSection {
    padding: 0px 20px 20px 20px;
    position: relative;
}


.teamListBlockSection {
    display:flex;
    gap: 10px;
    border-bottom: 1px solid #2C335A;
    padding: 0px 0px 8px 0px;
    cursor: pointer;
    margin-bottom: 10px;
}

.teamListBlockSection:first-child {
    padding-top: 0px;
  
}

.teamListBlockSection:last-child {
    border-bottom: none;
    padding-bottom: 10px;
    margin-bottom: 0px;

}

.teamListBlockSection img {
    width: 23px;
    height: 23px;
    object-fit: cover;
    border-radius: 50%;
}


.pronounSection  {
    margin-left: 5px;
    color: #426093;
    font-size: 9px;
    align-self: center;
}

.teamBlockSection h2 {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    display: block;

}

.teamListBlockSection>div:last-child {
    margin-left: auto;
    display: block;
    align-self: center;
    font-size: 8px; 
}

.teamListBlockSection>div:last-child i {
    color: #7c7c7c;
}

.teamBlockSection h3 {
    color: #426093;
    font-weight: 400;
    font-size: 10px;
 
}

.flexBoxStyleBlock {
    display:flex;
    margin-bottom: 3px;
}

.avatarRecipientList {
    object-fit: cover;
}

.teamDetailsSectionBlock {
    background:#050C21;
    border-radius: 15px;
    width: 350px;
    left: -330px;
    top:0px;
    z-index: 9;
    position:absolute;
    opacity: 1;
    animation: fadeIn 0.5s ease-in-out;
}


@keyframes fadeIn {
    0% {
        opacity: 0;
    } 100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


.backgroundImage {
    position:absolute;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    width:100%;
    display:block;
    background-attachment: fixed;
    object-fit: cover;
}

.containerSectionBlk {
    position:relative;
}
.myProfileContainerSection {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.myProfileContainerSection>div:first-child {
    width: 300px;
}

.myProfileContainerSection>div:last-child {
    background: none;
    flex: 1;
}


.personalDetailsMainContainer {
    max-height: 400px;
    overflow-x: hidden;
    border-radius: 15px;
}

.personalDetailsMainContainer::-webkit-scrollbar {
    width: 10px;
}

.personalDetailsMainContainer::-webkit-scrollbar-track {
    background: rgba(9, 23, 46, 1);
    border-radius: 10px;
}

.personalDetailsMainContainer::-webkit-scrollbar-thumb {
    background: rgb(18, 50, 101);
    border-radius: 10px;
    cursor: pointer;
}

.personalDetailsSectionBlk {
    align-items: center;
    padding:20px;
}

.headerPersonalDetailsSectionBlk {
    display: flex;
}


.personalDetailsSectionBlk hr {
    background: #2B315C;
    height: 1px;
    border: 0px;
}

.personalDetailsBioSection {
    margin-top: 7px;
}

.personalDetailsBioSection p {
    font-size: 12px;
}

.personalDetailsContactSection p {
    font-size: 12px;
    display: block;
    margin-bottom: 7px;
}

.jobTitleSectionBlk {
    display: inline-block;
    color: #7FC97F;
    padding: 5px 15px;
    border-radius: 50px;
    font-size: 12px;
    font-weight: 600;
    border: 2px solid #7FC97F;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.nameSectionBlk {
    display: flex;
    gap: 5px;
    align-items: center;
    margin-top: 10px;
}

.nameSectionBlk>div:first-child {
    max-width: 190px;
}

.nameSectionBlk>div:last-child {
    flex: 1;
}

.nameSectionBlk h1 {
    font-weight: 400;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    font-size: 29px;
    color: #fff;
}

.nameSectionBlk h3 {
    font-size: 12px;
    font-weight: 400;
    color: #8198BF;
}

.avatarImageProfileSection {
    background: rgb(220, 220, 220);
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 5px solid #fff;
    border-radius: 50%;
}


.levelSectionBlk {
    border-top: 1px solid #2B315C;
    padding: 10px;
}


.containerBadgesSection {
    padding: 0px 20px 20px 20px;
}

.containerBadgesSection h1 {
    font-size: 18px;
    color: #fff;
    display: block;
    margin-bottom: 15px;
    font-weight: 400;
    font-family: 'JosefinSans-Regular', Arial, Helvetica, sans-serif;
    color: #fff;
}

.badgeContainerList {
    display:flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}


.badgeContainerList img  {
   width: 30px;
}

.badgeContainerList h2 {
    font-size: 12px;
    color:#fff;
}

@media screen and (max-width:920px) {
   .teamDetailsSectionBlock {
    display:none;
   }
}