.flexBoxBlk  {
    gap: 30px;
    justify-content: center;
}

.flexBoxBlk a {
    font-size: 11px;
    color:#fff;
    font-weight: 600;
    text-decoration: none;
    transition:color 0.3s ease-in-out;
}

.flexBoxBlk a:hover {
   color: #01C2F3;
}

.flexBoxBlk i {
    margin-left: 7px;
    font-size: 7px;
    vertical-align: middle;
}